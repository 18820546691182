
import React from 'react';

import DWPButton from '../Buttons/DWPButton'
import './GroupExpirationInformation.scss';

const GroupExpirationInformation = ({
  groupExpiration,
  onRenewGroup,
  onCancelRenewal,
}) => {
  if (!groupExpiration) { return ''; }
  return (
    <div className="spgov-site-exp-info-wrapper">
      <div className="spgov-site-exp-info-title">
        <h3>Do you want to renew <a href={groupExpiration.groupReviewUrl} target="_blank" rel="noopener noreferrer">{groupExpiration.displayName}</a>?</h3>
      </div>

      <div className="spgov-site-exp-info-buttons">
        <DWPButton
          className="spgov-site-exp-info-cancel"
          header="Cancel"
          onButtonClick={onCancelRenewal}
        />
        <DWPButton
          className="spgov-site-exp-info-renew"
          header="Renew"
          onButtonClick={onRenewGroup}
          disabled={!groupExpiration.userIsOwner}
          isPrimary
        />
      </div>

      <p>
        Renew '{groupExpiration.displayName}' before it expires on <span className="spgov-bold">{groupExpiration.expirationDate}</span>.

        If it isn't renewed, it will be deleted. You received this email because you're an owner of the {groupExpiration.type}.
      </p>
    </div>
  )
};

export default GroupExpirationInformation;
