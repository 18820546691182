import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

const Warnings = ({ warnings, onDismissWarning }) => (
  warnings.map(warning => (
    <MessageBar
      key={warning.key}
      onDismiss={() => onDismissWarning(warning.key)}
      dismissButtonAriaLabel="Close"
      isMultiline={false}
      messageBarType={MessageBarType.warning}
    >
      <h4>{warning.header}</h4>{warning.message}
    </MessageBar>
  ))
);

export default Warnings;
