import React from 'react';
import FormError from '../Presentationals/FormError/FormError';

/* eslint-disable react/jsx-filename-extension */

export const chooseStateVariable = (a, b) => {
  if (a !== null && a !== undefined) {
    return a;
  } else if (b !== null && b !== undefined) {
    return b;
  }
  return '';
};


export const renderFormError = (error) => {
  if (error) {
    return (
      <FormError error={error} />
    );
  }
  return '';
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const GetDateString = (inputDate) => {
  if(!inputDate) { return ''; }

  var mm = (inputDate.getMonth() + 1) < 10 ? `0${inputDate.getMonth() + 1}` : inputDate.getMonth() + 1; // getMonth() is zero-based
  var dd = inputDate.getDate() < 10 ? `0${inputDate.getDate()}` : inputDate.getDate();
  var yyyy = inputDate.getFullYear();
  return `${yyyy}-${mm}-${dd}`
}