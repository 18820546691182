import { Spinner } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import SiteCollectionAPI from '../../../API/SiteCollectionAPI';
import Error from '../../Presentationals/Messages/Error/Error';
import SiteCollectionInfo from '../../Presentationals/SiteCollectionInfo/SiteCollectionInfo';
import './Vanity.scss';
import VanityForm from './VanityForm';

const getSpinnerLabel = (loading, posting) => {
  if (loading && !posting) { return 'Loading site information..' }
  else if (!loading && posting) { return 'Saving response..' }
  return '';
}


const Vanity = ({ match }) => {
  const [error, setError] = useState(null);
  const [loadingSurvey, setLoadingSurvey] = useState(true);
  const [surveyAnswered, setSurveyAnswered] = useState();

  const [loadingMigration, setLoadingMigration] = useState(true);
  const [vanityMigration, setVanityMigration] = useState();


  const [loading, setLoading] = useState(true);
  const [siteCollection, setSiteCollection] = useState(null);

  const [posting, setPosting] = useState(null);
  const [surveyPosted, setSurveyPosted] = useState(false);
  const [surveyAnswers, setSurveyAnswers] = useState({});

  const stageSurveyAnswers = (object) => {
    const newState = { ...surveyAnswers, ...object };
    setSurveyAnswers(newState);
  };

  useEffect(() => {
    SiteCollectionAPI.getSiteCollection(match.params.siteId)
      .then(_ => setSiteCollection(_))
      .catch(error => setError(error));
  }, []);

  useEffect(() => {
    if (error || siteCollection) setLoading(false);
  }, [siteCollection, error]);

  useEffect(() => {
    SiteCollectionAPI.getVanitySurvey(match.params.siteId)
      .then(_ => setSurveyAnswered(true))
      .catch(_ => setError(false));
  }, []);

  useEffect(() => {
    setLoadingSurvey(false);
  }, [surveyAnswered, error]);

  useEffect(() => {
    SiteCollectionAPI.getVanityMigration(match.params.siteId)
      .then(_ => setVanityMigration(true))
      .catch(_ => setError(false));
  }, []);
  useEffect(() => {
    if (error || vanityMigration) setLoadingMigration(false);
  }, [vanityMigration, error]);


  useEffect(() => {
    if (posting) {
      SiteCollectionAPI.postVanitySurvey(match.params.siteId, surveyAnswers)
        .then(_ => {
          setSurveyPosted(true);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (error || surveyPosted) setPosting(false);
  }, [surveyPosted, error])



  const renderContent = () => {
    if (loading || posting || loadingSurvey || loadingMigration) {
      return (
        <Spinner
          label={getSpinnerLabel(loading, posting)}
        />
      );
    }
    else if (surveyPosted) {
      return (
        <div className="app-info-center">
          <h3>Thank you</h3>
          <p>
            You will receive additional information once your response has been processed and we are ready to proceed with your site.
          </p>
        </div>
      )
    }
    else if (surveyAnswered) {
      return (
        <div className="app-info-center">
          <h3>You have already submitted answers for this site.</h3>
        </div>
      )
    }
    else if (vanityMigration.plannedMigration) {
      return (
        <div>
          {
            <h3><a href={siteCollection.url}>{siteCollection.title}</a> will be moved on the {vanityMigration.plannedMigration.substring(0, 10)}</h3>
          }
          {
            vanityMigration.migrationCategory === 0 && 'The site is considered an archive the previous answers has said that the site is in-active or no answer was given, the site will still be moved as-is.'
          }
          {
            !vanityMigration.plannedMigrationSent && 'The date is preliminary and might change.'
          }
        </div>
      )
    }
    else if (vanityMigration.plannedDeletion) {
      return (
        <div>
          <h3><a href={siteCollection.url}>{siteCollection.title}</a> will be deleted on the {vanityMigration.plannedDeletion.substring(0, 10)}</h3>
          {
            !vanityMigration.plannedDeletionSent && 'The date is preliminary and might change.'
          }
        </div>
      )
    }
    else if (siteCollection) {
      return (
        <div>
          <h3>
            Is <a href={siteCollection.url}>{siteCollection.title}</a> actively being used?
          </h3>
          <SiteCollectionInfo siteCollection={siteCollection} />

          <VanityForm surveyAnswers={surveyAnswers} stageSurveyAnswers={stageSurveyAnswers} postResponse={() => setPosting(true)} />

        </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className="app-page-wrapper">
      <Error error={error} onDismissError={() => setError(null)} />

      <div className="app-page-content">
        {
          renderContent()
        }
      </div>
    </div>
  )
};

export default Vanity;
