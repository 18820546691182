import React from 'react';

import { Spinner, Icon } from 'office-ui-fabric-react';

import './spgov-buttons.scss';

const renderButton = ({
  loading,
  completed,
  disabled,
  isPrimary,
  onButtonClick,
  text,
  loadingText,
  completedText,
  completedSubText
}) => {
  if (completed) {
    return (
      <div
        className="spgov-standard-button completed"
      >
        <Icon iconName="CheckMark" />
        <div>
          <h3 className="text">{completedText}</h3>
          <span className="subtext">{completedSubText}</span>
        </div>
      </div>
    );
  } else if (loading) {
    return (
      <div
        className="spgov-standard-button loading"
      >
        <Spinner
          label={`${loadingText}..`}
        />
      </div>
    );
  }
  else {
    return (
      <div
        className={`spgov-standard-button ${isPrimary ? 'isPrimary' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!disabled) onButtonClick()
        }}
      >
        <span>{text}</span>
      </div>
    );
  }
}

const StandardButton = (props) => {
  const {
    padded, className
  } = props;

  return (
    <div className={`spgov-standard-button-wrapper ${padded ? 'padded' : ''} ${className ? className : ''}`}>
      {
        renderButton(props)
      }
    </div >
  );
};

export default StandardButton;
