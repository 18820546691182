import React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
const Spacer = require('react-spacer')

export const defaultColumns =  [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'displayName',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    onRender: (item) => (
      <a className='groupLink' target='_blank' rel="noopener noreferrer" href={item.unifiedGroupUrl}>
        {
          item.displayName
        }
      </a>
    )
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 150,
    maxWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <div className='groupDescriptionWrapped'>
        {
          item.description
        }
      </div>
    )
  },
  // {
  //   key: 'createdFrom',
  //   name: 'Created From',
  //   fieldName: 'createdFrom',
  //   minWidth: 150,
  //   maxWidth: 200,
  //   isResizable: true
  // },
  {
    key: 'classification',
    name: 'Sensitivity',
    fieldName: 'classification',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'visibility',
    name: 'Privacy',
    fieldName: 'visibility',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'created',
    name: 'Created',
    fieldName: 'created',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    render: (text) => (
        <p>{text && text.substring(0, 10)}</p>
    ),
  },
  {
    key: 'owners',
    name: 'Owners',
    fieldName: 'owners',
    minWidth: 250,
    maxWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <ul className="app-owner-list">
        {
          item.owners.length>1 ?
          item.owners && item.owners.map(_ => (
            <li>{_.email}</li>
          ))
          :
          item.owners && item.owners.map(_ => (
            <li><div style={{ display: 'flex' }}>{_.email}</div></li>
          ))
        }
      </ul>
    )
  },
]

export const defaultDirectReportColumns = [
  {
    key: 'owners',
    name: 'Owners',
    fieldName: 'owners',
    minWidth: 300,
    maxWidth: 400,
    isResizable: true,
    onRender: (item) => (
      <ul className="app-owner-list">
        {
          item.owners.length > 1 ?
            item.owners && item.owners.map(_ => (
              <li>{_.email}</li>
            ))
            :
            item.owners && item.owners.map(_ => (
              <li>
                <div style={{ display: 'flex' }}>{_.displayName}</div>
                <div style={{ display: 'flex' }}>{_.email}
                  
                </div>
              </li>
            ))
        }
      </ul>
    )
  },
  {
    key: 'title',
    name: 'Title',
    fieldName: 'displayName',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    onRender: (item) => (
      <a className='groupLink' target='_blank' rel="noopener noreferrer" href={item.unifiedGroupUrl}>
        {
          item.displayName
        }
      </a>
    )
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 150,
    maxWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <div className='groupDescriptionWrapped'>
        {
          item.description
        }
      </div>
    )
  },
  {
    key: 'classification',
    name: 'Sensitivity',
    fieldName: 'classification',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'visibility',
    name: 'Privacy',
    fieldName: 'visibility',
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'created',
    name: 'Created',
    fieldName: 'created',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    render: (text) => (
      <p>{text && text.substring(0, 10)}</p>
    ),
  }
  
]
