import React from 'react';
import { Icon } from 'office-ui-fabric-react';

import './GroupRenewalCancelled.scss';

const GroupRenewalCancelled = ({group}) => (
    <div className="spgov-site-exp-info-cancelled">
      <Icon iconName="StatusErrorFull" />
      <h2>Renewal Cancelled</h2>
      <p>You can still renew the {group.type} by clicking the link in the email!</p>
    </div>
);

export default GroupRenewalCancelled;