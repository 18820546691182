import { UserAgentApplication } from "msal";

const hostname = window && window.location && window.location.hostname
let clientId

if (hostname === 'mygroups.volvocars.net' || hostname === 'vcc-o365-governance.azurewebsites.net' ) {
  clientId = 'f0444243-a43a-4c12-9ec6-3b7d4877e3e0';
}
else {
  clientId = '278b077e-3f49-4139-bbfe-f0e2d753ab2e';
}

const tokenRequest = {
  scopes: ['https://volvocars.onmicrosoft.com/' + clientId + '/user_impersonation']
};

export default class AuthService {
  constructor() {
    var msalConfig = {
      auth: {
        clientId,
        authority: 'https://login.microsoftonline.com/81fa766e-a349-4867-8bf4-ab35e250a08f/',
        redirectUri: `${window.location.protocol}//${window.location.host}/`,
      }
    };

    this.userApp = new UserAgentApplication(msalConfig);
    this.userApp.handleRedirectCallback(() => false)
  }

  static getCurrent = () => {
    if (!AuthService.authService) {
      AuthService.authService = new AuthService();
    }
    return AuthService.authService;
  }

  login = () => {
    if (window.location.href.indexOf('#id_token') >= 0) {
      return;
    }
    if (sessionStorage.getItem('msal.interaction.status')) {
      return '';
    }
    const userAcc = this.userApp.getAccount();
    if (!userAcc) {
      this.userApp.loginRedirect(tokenRequest);
    } else {
      return userAcc;
    }
  }

  getAccessToken = async () => {
    let token;
    try {
      try {
        token = await this.userApp.acquireTokenSilent(tokenRequest);
      } catch (error) {
        console.warn('Initial AcquireTokenSilent failed: ', error);
        console.log('Attempting AcquireTokenRedirect..');
        token = await this.userApp.acquireTokenRedirect(tokenRequest);
      }
    } catch (error) {
      console.warn('AcquireTokenRedirect failed: ', error);
      console.log('Attempting (PopUp) Login..');
      try {
        await this.userApp.loginPopup();
      } catch (error) {
        console.warn('Initial PopUp Login failed: ', error);
        console.log('Attempting (Redirect) Login..');
        token = await this.userApp.loginRedirect(tokenRequest);
      }

      try {
        token = await this.userApp.acquireTokenSilent(tokenRequest);
      } catch (error) {
        console.warn('Initial AcquireTokenSilent failed: ', error);
        console.log('Attempting AcquireTokenRedirect..');
        token = await this.userApp.acquireTokenRedirect(tokenRequest);
      }
    }
    return token ? token.accessToken : token;
  }

  getAuthHeaders = async () => {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${await this.getAccessToken()}`
    };
  }

};
