import React from 'react';

import './SiteCollectionInfo.scss';

const SiteCollectionInfo = ({ siteCollection }) => {
  return (
    <div className="app-sitecollection-info">
      <div>

        <div className="app-sitecollection-info-row">
          <div className="app-sitecollection-info-column">Storage usage (MB):</div>
          <div className="app-sitecollection-info-column">
            {siteCollection.storageUsage}
          </div>
        </div>

        <div className="app-sitecollection-info-row">
          <div className="app-sitecollection-info-column">Last Content Change:</div>
          <div className="app-sitecollection-info-column">
            {siteCollection.lastUserModified}
          </div>
        </div>

        <div className="app-sitecollection-info-row">
          <div className="app-sitecollection-info-column">Owners/Admins:</div>
          <div className="app-sitecollection-info-column">
            <ul>
              {siteCollection.siteOwners && siteCollection.siteOwners.map((_, i) => (
                <li className="app-sitecollection-info-teamslink" key={`app-sitecollection-info-teamslink-${i}`}>
                  <img
                    alt="TeamsIcon"
                    src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg"
                  />
                  <a
                    href="https://teams.microsoft.com/l/chat/1/0?users={_}"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {_}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteCollectionInfo;
