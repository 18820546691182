
import React from 'react';

import DWPButton from '../Buttons/DWPButton'
import './SiteExpirationInformation.scss';

const SiteExpirationInformation = ({
  siteExpiration,
  onRenewSite,
  onCancelRenewal,
}) => {
  if (!siteExpiration) { return ''; }
  return (
    <div className="spgov-site-exp-info-wrapper">
      <div className="spgov-site-exp-info-title">
        <h3>Do you want to renew <a href={siteExpiration.url} target="_blank" rel="noopener noreferrer">{siteExpiration.title}</a>?</h3>
      </div>

      <div className="spgov-site-exp-info-buttons">
        <DWPButton
          className="spgov-site-exp-info-cancel"
          header="Cancel"
          onButtonClick={onCancelRenewal}
        />
        <DWPButton
          className="spgov-site-exp-info-renew"
          header="Renew"
          onButtonClick={onRenewSite}
          disabled={!siteExpiration.userIsOwner}
          isPrimary
        />
      </div>

      <p>
        Renew '{siteExpiration.title}' before it expires on <span className="spgov-bold">{siteExpiration.expirationDate}</span>.

        If it isn't renewed, it will be deleted, along with all associated communications, files, calendar events, and tasks. You received this email because you're an owner of the group/site.
      </p>
    </div>
  )
};

export default SiteExpirationInformation;
