import React from 'react';

import './spgov-buttons.scss';

const DWPButton = ({ className, onButtonClick, header, text, isMultiline, isPrimary, isNegative, disabled }) => (
  <button
    className={`${className} spgov-dwp-button ${isPrimary ? 'isPrimary' : ''} ${isNegative ? 'isNegative' : ''} ${disabled ? 'disabled' : ''}`}
    onClick={onButtonClick}
    disabled={disabled}
  >
    <div>
      <h3>{header}</h3>
      {
        isMultiline
          ? <p>{text}</p>
          : null
      }
    </div>
  </button>
);

export default DWPButton;