import { Spinner } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SiteCollectionAPI from '../../../API/SiteCollectionAPI';
import StandardButton from '../../Presentationals/Buttons/StandardButton';
import Error from '../../Presentationals/Messages/Error/Error';
import SiteCollectionInfo from '../../Presentationals/SiteCollectionInfo/SiteCollectionInfo';
import './VanityCancelDeletionPage.scss';

const getSpinnerLabel = (loading, posting) => {
  if (loading && !posting) { return 'Loading site information..' }
  else if (!loading && posting) { return 'Saving response..' }
  return '';
}


const VanityCancelDeletionPage = ({ match, history }) => {
  const [error, setError] = useState(null);
  const [loadingVanityMigration, setLoadingVanityMigration] = useState(true);
  const [deletionCanceled, setDeletionCanceled] = useState();

  const [loading, setLoading] = useState(true);
  const [siteCollection, setSiteCollection] = useState(null);

  const [posting, setPosting] = useState(null);
  const [cancelationPosted, setCancelationPosted] = useState(false);

  useEffect(() => {
    SiteCollectionAPI.getSiteCollection(match.params.siteId)
      .then(_ => setSiteCollection(_))
      .catch(error => setError(error));
  }, []);

  useEffect(() => {
    if (error || siteCollection) setLoading(false);
  }, [siteCollection, error]);

  useEffect(() => {
    SiteCollectionAPI.getVanityMigration(match.params.siteId)
      .then(_ => setDeletionCanceled(_.plannedDeletion === null))
      .catch(_ => setDeletionCanceled(false));
  }, []);

  useEffect(() => {
    setLoadingVanityMigration(false);
  }, [deletionCanceled, error]);

  useEffect(() => {
    if (posting) {
      SiteCollectionAPI.cancelVanityDeletion(match.params.siteId)
        .then(_ => {
          setCancelationPosted(true);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (error || cancelationPosted) setPosting(false);
  }, [cancelationPosted, error])

  const renderContent = () => {
    if (loading || posting || loadingVanityMigration) {
      return (
        <Spinner
          label={getSpinnerLabel(loading, posting)}
        />
      );
    }
    else if (cancelationPosted) {
      return (
        <div className="app-info-center">
          <h3>Deletion of Site has been Canceled</h3>
          <p>Please answer the survey to determine the next step.</p>
          <div className="app-right-button-wrapper">
            <StandardButton onButtonClick={() => history.push(`/sites/${match.params.siteId}/vanitysurvey`)} text="Respond to Survey" />
          </div>
        </div>
      )
    }
    else if (deletionCanceled) {
      return (
        <div className="app-info-center">
          <h3>The deletion of this site has already been canceled</h3>
          <div className="app-right-button-wrapper">
            <StandardButton onButtonClick={() => history.push(`/sites/${match.params.siteId}/vanitysurvey`)} text="Respond to Survey" />
          </div>
        </div>
      )
    }
    else if (siteCollection) {
      return (
        <div className="app-info-center">
          <h3>
            Do you want to cancel the deletion of <a href={siteCollection.url}>{siteCollection.title}</a>?
          </h3>
          
          <div className="app-right-button-wrapper">
            <StandardButton onButtonClick={() => setPosting(true)} text="Cancel Deletion" />
          </div>

        </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className="app-page-wrapper">
      <Error error={error} onDismissError={() => setError(null)} />

      <div className="app-page-content">
        {
          renderContent()
        }
      </div>
    </div>
  )
};

export default VanityCancelDeletionPage;
