import React from 'react';
/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
/* eslint-enable no-unused-vars */
import { Icon } from 'office-ui-fabric-react/lib/Icon';

import './headertoolbar.scss';

const HeaderToolbar = ({ user }) => (
  <div className="app-header-toolbar">
    <div className="app-header-toolbar-item">
      <Icon iconName="Contact" className="app-header-toolbar-user" />
    </div>
    <div className="app-header-toolbar-item-multiline">
      <div className="app-header-toolbar-usertext">
        <span>{user ? user.name : ''}</span>
      </div>
    </div>
  </div>
);

export default HeaderToolbar;
