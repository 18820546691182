import React from 'react';
import { Icon } from 'office-ui-fabric-react';

import './SiteDeleted.scss';

const SiteDeleted = ({ siteCollection }) => (
  <div className="spgov-site-info-deleted">
    <Icon iconName="Delete" />
    <h2>{siteCollection.title} has been deleted</h2>
    <p>
      To restore the site, please go to <a
        href="https://volvocars.service-now.com/sp?id=sc_cat_item&sys_id=f7425ec6b5c5b100a7f8391a9c617a09"
        target="_blank"
        rel="noopener noreferrer" 
      >
        Restore a SharePoint Site
      </a> and fill in the Request.
    </p>
  </div>
);

export default SiteDeleted;
