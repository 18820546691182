import React from 'react';
import { Icon } from 'office-ui-fabric-react';

import './GroupDeleted.scss';

const GroupDeleted = ({ group }) => (
  <div className="spgov-site-info-deleted">
    <Icon iconName="Delete" />
    <h2>{group.displayName} has been deleted</h2>
    <p>
      To restore the {group.type}, please go to <a
        href="https://volvocars.service-now.com/sp?id=kb_article&sys_id=1a5cda8edbf077c00387fcb6ae961939"
        target="_blank"
        rel="noopener noreferrer" 
      >
        Restore a {group.type}
      </a> and fill in the Request.
    </p>
  </div>
);

export default GroupDeleted;
