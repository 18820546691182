import { MessageBar } from 'office-ui-fabric-react';
import React from 'react';
import { SiteActivity } from '../../../Commons/enums';
import StandardButton from '../../Presentationals/Buttons/StandardButton';
import VanityQuestions from './VanityQuestions';

const renderInfoBox = (value) => {
  if (value === SiteActivity.Active) {
    return (
      <MessageBar>
        Active – the site will be migrated. You will receive a mail with a time for migration as soon as the migration plan is set. After the move is completed you will get a confirmation mail with steps to follow if you are experiencing any issues.
      </MessageBar>
    )
  } else if (value === SiteActivity.RequiresBackup) {
    return (
      <MessageBar>
        Inactive – the site will be migrated and archived. You receive an email confirming roughly when site is migrated and a new confirmation email once the migration is done.
      </MessageBar>
    )
  } else if (value === SiteActivity.Obsolete) {
    return (
      <MessageBar>
        No longer in use – a mail is sent to all site owners giving them the possibility to object. If no action is taken, the site will be deleted. It is possible to restore the site within 30 days after the deletion.
      </MessageBar>
    )
  }
}

const SurveyQuestion = ({ question, surveyAnswers, stageSurveyAnswers }) => {
  const {
    propertyName,
    text,
    helpText,
    options
  } = question

  const onOptionChange = (opt) => {
    const change = {};
    change[propertyName] = opt.value;
    stageSurveyAnswers(change);
  }

  return (
    <div className={`app-vanity-form-customizations-row ${question.renderIf && !question.renderIf(surveyAnswers) ? 'hide' : 'show'}`}>
      <h4>{text}</h4>
      {
        helpText
          ? <p className="app-vanity-form-helptext">{helpText}</p>
          : null
      }

      {
        options.map((_, i) => (
          <div className="app-vanity-form-customizations-option" key={`${propertyName}-${i}`}>
            <input
              id={`${propertyName}-${i}`}
              type="radio"
              name={propertyName}
              value={_}
              onChange={() => onOptionChange(_)}
              checked={surveyAnswers[propertyName] === _.value}
            />
            <label htmlFor={`${propertyName}-${i}`}>
              {
                _.label
              }
            </label>
          </div>
        ))
      }

      {
        propertyName === 'siteActivity'
          ? (
            <div className="app-vanity-form-infobox">
              { renderInfoBox(surveyAnswers[propertyName])}
            </div>
          ) : null
      }
    </div>
  );
};

const VanityForm = ({ surveyAnswers, stageSurveyAnswers, postResponse }) => {
  const validateAnswers = () => {
    let valid = true;

    if (surveyAnswers.siteActivity !== undefined && surveyAnswers.siteActivity === SiteActivity.Active) {
      valid = surveyAnswers.acceptedDowntimeDays > 0 && surveyAnswers.hasDependencies !== undefined
    }
    else {
      valid = surveyAnswers.siteActivity !== undefined && (
        surveyAnswers.siteActivity === SiteActivity.RequiresBackup ||
        surveyAnswers.siteActivity === SiteActivity.Obsolete
      );
    }

    return valid;
  }

  return (
    <div className="">
      <div className="app-vanity-form-customizations">

        {
          VanityQuestions.map(_ => (
            <SurveyQuestion
              key={_.propertyName}
              question={_}
              surveyAnswers={surveyAnswers}
              stageSurveyAnswers={stageSurveyAnswers}
            />
          ))
        }

        <p className="app-vanity-form-is-required">Answers to these questions are required.</p>

      </div>

      <div className="app-right-button-wrapper">
        <StandardButton onButtonClick={postResponse} text="Send response" disabled={!validateAnswers()} />
      </div>
    </div>
  );
};

export default VanityForm;
