import React from 'react';
import { Icon } from 'office-ui-fabric-react';

import './GroupRenewalSuccess.scss';

const GroupRenewalSuccess = ({ renewal, group }) => (
    <div className="spgov-site-exp-info-success">
      <Icon iconName="SkypeCircleCheck" />
      <h2>Renewal Successful</h2>
      <p>'{renewal.displayName}' has been successfully renewed, the {group.type} will expire <span className="spgov-bold">{renewal.expirationDate}</span>.</p>
    </div>
);

export default GroupRenewalSuccess;