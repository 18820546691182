import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

const Error = ({ error, onDismissError }) => (
  error
    ? (
      <MessageBar
        onDismiss={onDismissError}
        dismissButtonAriaLabel="Close"
        isMultiline={false}
        messageBarType={MessageBarType.error}
      >
        <h4>{error.header}</h4>{error.message}
      </MessageBar>
    )
    : ''
);

export default Error;
