import { Spinner } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import SiteCollectionAPI from '../../../API/SiteCollectionAPI';
import StandardButton from '../../Presentationals/Buttons/StandardButton';
import Error from '../../Presentationals/Messages/Error/Error';
import './VanityReschedulePage.scss';

import { DatePicker, DayOfWeek } from '@fluentui/react';
import { addDays } from '@fluentui/date-time-utilities';
import { GetDateString } from '../../Commons/HelperFunctions';

const getSpinnerLabel = (loading, posting) => {
  if (loading && !posting) { return 'Loading site information..' }
  else if (!loading && posting) { return 'Saving response..' }
  return '';
}

const today = new Date(Date.now());
const minDate = addDays(today, -5);
const maxDate = addDays(today, 10);

const VanityReschedulePage = ({ match, history }) => {
  const [error, setError] = useState(null);
  const [loadingVanityMigration, setLoadingVanityMigration] = useState(true);
  const [vanityMigration, setVanityMigration] = useState();

  const [loading, setLoading] = useState(true);
  const [siteCollection, setSiteCollection] = useState(null);

  const [posting, setPosting] = useState(null);
  const [suggestedPosted, setSuggestedPosted] = useState(false);

  
  const [suggestedMigrationDate, setSuggestedMigrationDate] = useState(null);

  useEffect(() => {
    SiteCollectionAPI.getSiteCollection(match.params.siteId)
      .then(_ => setSiteCollection(_))
      .catch(error => setError(error));
  }, []);

  useEffect(() => {
    if (error || siteCollection) setLoading(false);
  }, [siteCollection, error]);

  useEffect(() => {
    SiteCollectionAPI.getVanityMigration(match.params.siteId)
      .then(_ => setVanityMigration(_))
      .catch(_ => setVanityMigration(false));
  }, []);

  useEffect(() => {
    setLoadingVanityMigration(false);
  }, [vanityMigration, error]);

  useEffect(() => {
    if (posting && suggestedMigrationDate) {
      setError(null);
      SiteCollectionAPI.rescheduleMigration(match.params.siteId, { suggestedMigrationDate: GetDateString(suggestedMigrationDate) })
        .then(_ => {
          setSuggestedPosted(true);
          setVanityMigration(_);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (error || suggestedPosted) setPosting(false);
  }, [suggestedPosted, error])

  const renderContent = () => {
    if (loading || posting || loadingVanityMigration || !vanityMigration) {
      return (
        <Spinner
          label={getSpinnerLabel(loading, posting)}
        />
      );
    }
    else if (suggestedPosted) {
      return (
        <div className="app-info-center">
          <h3>Rescheduling Migration for {siteCollection.title} completed</h3>
          <p>The migration will be started on {vanityMigration.plannedMigration}.</p>
        </div>
      )
    }
    else if (vanityMigration.allowReschedule === false) {
      return (
        <div className="app-info-center">
          <h3>Rescheduling of Migration is not allowed for {siteCollection.title}</h3>
        </div>
      )
    }
    else if (siteCollection) {
      return (
        <div className="app-info-center">
          <h3>
            Reschedule Migration for {siteCollection.Title}
          </h3>

          <div>
            <DatePicker
              isRequired={true}
              firstDayOfWeek={DayOfWeek.Monday}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              minDate={addDays(new Date(vanityMigration.plannedMigration), -5)}
              maxDate={addDays(new Date(vanityMigration.plannedMigration), 10)}
              allowTextInput={true}
              showMonthPickerAsOverlay={true}
              defaultValue={vanityMigration.plannedMigration}
              value={suggestedMigrationDate || new Date(vanityMigration.plannedMigration)}
              onSelectDate={setSuggestedMigrationDate}
            />
            <p className="italics">The Migration will be started at 18:00 CET on the selected day to avoid issues during peak hours.</p>
          </div>
          
          <div className="app-right-button-wrapper">
            <StandardButton onButtonClick={() => setPosting(true)} text="Reschedule" disabled={suggestedMigrationDate === null}/>
          </div>

        </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className="app-page-wrapper">
      <Error error={error} onDismissError={() => setError(null)} />

      <div className="app-page-content">
        {
          renderContent()
        }
      </div>
    </div>
  )
};

export default VanityReschedulePage;
