import { ValidateResponse } from './Exceptions';
import AuthAPI from './AuthAPI';

const baseUrl = window.location.origin;

export default async (method, endpoint, payload) => fetch(`${baseUrl}${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'credentials': 'same-origin',
      ...(await AuthAPI.getCurrent().getAuthHeaders()),
    },
    body: payload ? JSON.stringify(payload) : null,
  })
    .then(response => ValidateResponse(response))
    .catch((error) => { throw error; })
    .then(response => response)
