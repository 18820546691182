import { DetailsList, DetailsListLayoutMode, SearchBox, SelectionMode, Spinner, TextField } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import SiteCollectionAPI from '../../../API/SiteCollectionAPI';
import Error from '../../Presentationals/Messages/Error/Error';
import './VanityUATSites.scss';

import defaultColumns from './VanityUatSiteColumns';

const getSpinnerLabel = (loading) => {
  if (loading) { return 'Loading sites..' }
  return '';
}

const VanityUATSites = ({ match, history }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [siteCollections, setSiteCollections] = useState(null);
  const [items, setItems] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [searching, setSearching] = useState(false);


  useEffect(() => {
    SiteCollectionAPI.getVanityUATSites(match.params.siteId)
      .then(_ => {
        setSiteCollections(_);
        setItems(_);
      })
      .catch(error => setError(error));
  }, []);

  useEffect(() => {
    if (error || siteCollections) setLoading(false);
  }, [siteCollections, error]);

  useEffect(() => {
    setSearching(false);
  }, [items]);

  const onSearch = (searchTerm) => {
    setSearching(true);
    const matchingItems = siteCollections
      ? siteCollections.filter(_ => _.siteCollectionUrl.includes(searchTerm) || _.destinationSiteUrl.includes(searchTerm))
      : [];

    setItems(matchingItems)
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Spinner
          label={getSpinnerLabel(loading)}
        />
      );
    }
    else if (siteCollections) {
      return (
        <div className="">
          <div className="app-vanity-uat-header">
            <h3>
              Sites in Vanity Migration UAT
            </h3>
            <SearchBox
              placeholder="Search for a URL.."
              onSearch={onSearch}
            />
          </div>

          <div>
            <DetailsList
              items={items}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className="app-page-wrapper">
      <Error error={error} onDismissError={() => setError(null)} />

      <div className="app-page-content wide">
        {
          renderContent()
        }
      </div>
    </div>
  )
};

export default VanityUATSites;
