import React, { useState, useEffect } from 'react';

import AccessReviewAPI from '../../../API/AccessReviewAPI';

import { Spinner, Icon } from 'office-ui-fabric-react';

import Warnings from '../../Presentationals/Messages/Warnings/Warnings';
import Error from '../../Presentationals/Messages/Error/Error';
import AccessReviewCategory from '../../StateComponents/AccessReviewCategory/AccessReviewCategory';
import AccessReviewInfo from '../../Presentationals/AccessReviewInfo/AccessReviewInfo';

import './AccessReview.scss';
import { findIndexOfId } from '../../../Service/UtilityService';

const AccessReview = (props) => {
  const [error, setError] = useState();
  const [warnings, setWarnings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accessReview, setAccessReview] = useState();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    AccessReviewAPI.getSiteAccessReview(props.match.params.reviewId)
      .then(accessReview => {
        setAccessReview(accessReview);
        setError(null);
        setLoading(false);
      })
      .catch(error => setError(error));
  }, [props.match.params.reviewId]);

  const onCompleteTask = (categoryId, taskId) => {
    const categoryIndex = findIndexOfId(accessReview.categories, categoryId);
    const taskIndex = findIndexOfId(accessReview.categories[categoryIndex].tasks, taskId);

    return new Promise(function (resolve, reject) {
      AccessReviewAPI.postCompleteTask(accessReview.id, taskId)
        .then((resp) => {
          const accessReviewUpdated = { ...accessReview };
          accessReviewUpdated.categories[categoryIndex].tasks[taskIndex] = resp;
          setAccessReview(accessReviewUpdated);
          setError(null);
          resolve();
        })
        .catch(error => {
          setError(error);
          reject();
        });
    });
  };

  const onCompleteAccessReview = () => {
    setSaving(true);
    AccessReviewAPI.postCompleteAccessReview(accessReview.id)
      .then((accessReview) => {
        setAccessReview(accessReview);
        setError(null);
        setSaving(false);
      })
      .catch(error => {
        setError(error);
        setSaving(false);
      });
  }
  const dismissWarning = warningIdentifier => {
    setWarnings(warnings.filter(_ => _.key !== warningIdentifier))
  };

  const dismissError = () => setError(null);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spinner
            label="Loading Access Review.."
          />
        </div>
      );
    }

    return (
      <div className="access-review-wrapper padded">
        {
          accessReview
            ? <AccessReviewInfo
              accessReview={accessReview}
              onCompleteAccessReview={onCompleteAccessReview}
              saving={saving}
            />
            : ''
        }

        <div id="access-review-categories">
          {
            accessReview && accessReview.categories && accessReview.categories.map(category => (
              <AccessReviewCategory
                key={`category-${category.name}`}
                category={category}
                accessReviewId={accessReview.id}
                onCompleteTask={onCompleteTask}
              />
            ))
          }
        </div>
      </div>
    );
  }

  return (
    <div className="app-page-wrapper">
      <Warnings warnings={warnings} onDismissWarning={dismissWarning} />
      <Error error={error} onDismissError={dismissError} />

      {
        accessReview && accessReview.completed
          ? (
            <div className="access-review-confirmation">
              <div className="confirm-icon">
                <Icon iconName="CheckMark" />
              </div>
              <div className="confirm-text">
                <h3>Access Review Complete</h3>
                <p>The Access Review was completed by {accessReview.completedBy}.</p>
              </div>
            </div>
          ) : null
      }

      <div className="app-page-content">
        {
          this.renderContent()
        }
      </div>
    </div>
  );
};

export default AccessReview;
