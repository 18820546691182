import React from 'react';
/* eslint-disable no-unused-vars */
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
/* eslint-enable no-unused-vars */

import './appbar.scss';
import HeaderToolbar from '../HeaderToolbar/HeaderToolbar';

const AppBar = ({ user }) => (
  <div className="app-main-appbar">
    <div 
      className="appbar-dwp"
    />
    <div 
      className="appbar-navigation"
    >

    </div>
    <HeaderToolbar user={user} />
  </div>
);

export default AppBar;
