import React from 'react';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

export default [
    {
        key: 'siteCollectionId',
        name: 'Id',
        fieldName: 'siteCollectionId',
        minWidth: 50,
        maxWidth: 50,
    },
    {
        key: 'siteCollectionUrl',
        name: 'Original Site Url',
        fieldName: 'siteCollectionUrl',
        isResizable: true,
        minWidth: 400,
        maxWidth: 500,
        onRender: (item) => (
            <TooltipHost content={item.siteCollectionUrl}>
                <p>{item.siteCollectionUrl}</p>
            </TooltipHost>
        ),
    },
    {
        key: 'destinationSiteUrl',
        name: 'New Site Url',
        fieldName: 'destinationSiteUrl',
        isResizable: true,
        minWidth: 400,
        maxWidth: 700,
        onRender: (item) => (
            <TooltipHost content={item.destinationSiteUrl}>
                <p>{item.destinationSiteUrl}</p>
            </TooltipHost>
        ),
    },
    {
        key: 'migrationEnded',
        name: 'Migration Completed',
        fieldName: 'migrationEnded',
        minWidth: 200,
        maxWidth: 200,
        isSorted: true,
        isSortedDescending: true,
        onRender: (item) => (
            <p>{item.migrationEnded && item.migrationEnded.substring(0, 10)}</p>
        ),
    },
    {
        key: 'hasUnresolvedIssue',
        name: 'Has Unresolved Issue',
        fieldName: 'hasUnresolvedIssue',
        minWidth: 150,
        maxWidth: 200,
    },
]