import React from 'react';


export const defaultColumns =  [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'title',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'url',
    name: 'URL',
    fieldName: 'url',
    minWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <a href={item.url}>{item.url}</a>
    )
  },
  {
    key: 'owners',
    name: 'Owners',
    fieldName: 'siteOwners',
    minWidth: 250,
    maxWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <ul className="app-owner-list">
        {
          item.siteOwners && item.siteOwners.map(_ => (
            <li>{_}</li>
          ))
        }
      </ul>
    )
  }
]

export const vanityColumns =  [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'siteCollectionTitle',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: 'url',
    name: 'URL',
    minWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <a href={item.siteCollectionUrl}>{item.siteCollectionUrl}</a>
    )
  },
  {
    key: 'move-status',
    name: 'Move Status',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    onRender: (item) => {
      var status = ''

      if(item.migrationEnded && item.migrationCompleted) { status = 'Move Complete'; }
      else if((!item.migrationEnded && item.migrationStarted) || (item.migrationEnded && !item.migrationCompleted)) { status = 'In Progress..'; }
      else if(item.plannedMigrationSent) { status = 'Date Confirmed..' }
      else if(item.plannedMigration) { status = 'Date Preliminary..' }
      else { status = 'Planning Pending..' }

      return (<p>{status}</p>)
    }
  },
  {
    key: 'planned-move',
    name: 'Planned Move',
    minWidth: 150,
    maxWidth: 200,
    isResizable: true,
    onRender: (item) => {
      const txt = item.plannedMigration
        ? item.plannedMigration.substring(0, 10)
        : ''
      return (<p>{txt}</p>)
    }
  },
  {
    key: 'owners',
    name: 'Owners',
    minWidth: 250,
    maxWidth: 300,
    isResizable: true,
    onRender: (item) => (
      <ul className="app-owner-list">
        {
          item.siteCollectionOwners && item.siteCollectionOwners.map(_ => (
            <li>{_}</li>
          ))
        }
      </ul>
    )
  }
]
