import httpRequest from './HttpService';

export default {
  getSiteCollection: (id) => httpRequest('GET', `/api/sites/${id}`),
  getSiteCollections: () => httpRequest('GET', `/api/sites`),
  getVanitySites: () => httpRequest('GET', `/api/sites/vanity`),

  getVanitySurvey : (id) => httpRequest('GET', `/api/sites/${id}/vanity`),
  postVanitySurvey : (id, data) => httpRequest('POST', `/api/sites/${id}/vanity`, data),

  getVanityMigration : (id) => httpRequest('GET', `/api/sites/${id}/vanity/migration`),
  cancelVanityDeletion : (id) => httpRequest('POST', `/api/sites/${id}/vanity/deletion`),
  rescheduleMigration : (id, payload) => httpRequest('POST', `/api/sites/${id}/vanity/reschedule`, payload),
  getVanityUATSites: () => httpRequest('GET', `/api/sites/vanity-uat`),

}
