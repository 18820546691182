/**
 * Code should conform to the airbnb javascript styleguide to pass eslint
 * http://airbnb.io/javascript/
 * For exceptions, see .eslintrc
 */

/* eslint-disable react/jsx-filename-extension */

import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

import React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router
} from 'react-router-dom';

import App from './MasterComponents/App/App';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

const MOUNT_NODE = document.getElementById('root');

initializeIcons();

render(
  <Router strict>
    <App />
  </Router>,
  MOUNT_NODE,
);
