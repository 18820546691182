import React from 'react';
/* eslint-disable no-unused-vars */
import {
  withRouter, BrowserRouter as Router, Route, Link,
} from 'react-router-dom';
/* eslint-enable no-unused-vars */

import HeaderToolbar from '../HeaderToolbar/HeaderToolbar';
import './header.scss';


const Header = withRouter(({ history, links, user, transparent }) => (
  <div className={`app-main-header ${transparent ? 'transparent' : ''}`}>
    <div className="spgov-logo-wrapper">
      <Link to="/">
        <img src="./volvo_spread.svg" alt="Volvo"></img>
      </Link>
    </div>
  </div>
));

export default Header;
