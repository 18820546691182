import React from 'react';
import { Icon } from 'office-ui-fabric-react';

import './SiteRenewalCancelled.scss';

const SiteRenewalCancelled = () => (
    <div className="spgov-site-exp-info-cancelled">
      <Icon iconName="StatusErrorFull" />
      <h2>Renewal Cancelled</h2>
      <p>You can still renew the site by clicking the link in the email!</p>
    </div>
);

export default SiteRenewalCancelled;