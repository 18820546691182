import React, { useEffect, useState } from 'react';
import './MyGroups.scss';
import { DetailsList, SelectionMode, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { defaultDirectReportColumns } from './columns';

const MyReporteesGroups = ({ groups }) => {
  
  const renderContent = () => {
    
    return (
      <div className="app-mygroups-list-wide">
        {
          groups && groups.length > 0
            ? <DetailsList
              columns={defaultDirectReportColumns}
              items={groups.sort(function (x, y) {
                let a = x.displayName.toUpperCase(),
                  b = y.displayName.toUpperCase();
                return a == b ? 0 : a > b ? 1 : -1;
              })}
              onRenderRow={(props, defaultRender) => (
                props.item['owners'].length == 1 ?
                  <div className='single-owner'>
                    {defaultRender({ ...props, styles: { root: { background: '#f9e2ea', borderLeftWidth: 'thick', borderLeftStyle: 'solid', borderLeftColor: '#c0432c' } } })}
                  </div>
                  :
                  <div className='ms-List-cell'>
                    {defaultRender({ ...props })}
                  </div>
              )}
              selectionPreservedOnEmptyClick={true}
              selectionMode={SelectionMode.none}
              checkboxVisibility={false}
            />
            : <p> Oh, nothing to act upon here.</p>
        }
      </div>
    )
  }

  return (
    <div>
      <div>
        {
          renderContent()
        }
      </div>
    </div>
  );
}

export default MyReporteesGroups;
