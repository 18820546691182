import React, { useEffect, useState } from 'react';

import SiteCollectionAPI from '../../../API/SiteCollectionAPI';

import Warnings from '../../Presentationals/Messages/Warnings/Warnings';
import Error from '../../Presentationals/Messages/Error/Error';

import './MySites.scss';
import { DetailsList, MessageBar, SelectionMode, Spinner } from 'office-ui-fabric-react';
import { defaultColumns, vanityColumns } from './columns';
import { Link } from 'react-router-dom';

const MySites = ({ location }) => {
  const [warnings, setWarnings] = useState([])
  const [error, setError] = useState(null);
  const [onlyVanitySites, setOnlyVanitySites] = useState(false);

  const dismissWarning = (warningIdentifier) => setWarnings(warnings.filter(_ => _.key !== warningIdentifier));
  const dismissError = () => setError(null);

  const [loadingSiteCollections, setLoadingSiteCollections] = useState(true);
  const [siteCollections, setSiteCollections] = useState([]);

  useEffect(() => {
    if (siteCollections) setLoadingSiteCollections(false);
  }, [siteCollections]);

  useEffect(() => {
    setLoadingSiteCollections(true);
  }, [onlyVanitySites])


  useEffect(() => {
    if (location.search.includes('onlyVanitySites=1'))
      setOnlyVanitySites(true)
    else
      setOnlyVanitySites(false)
  }, [location])

  useEffect(() => {
    if (loadingSiteCollections) {
      if (location.search.includes('onlyVanitySites=1')) {
        SiteCollectionAPI.getVanitySites()
        .then(_ => setSiteCollections(_))
        .catch(error => setError(error));
      }
      else {
        SiteCollectionAPI.getSiteCollections()
        .then(_ => setSiteCollections(_))
        .catch(error => setError(error));
      }
      
    }
  }, [loadingSiteCollections]);

  const renderContent = () => {
    if (loadingSiteCollections) {
      return (
        <Spinner
          label="Getting site collections.."
        />
      );
    }
    return (
      <div>
        <div>

          <div className="app-mysites-list-wide">
            {
              siteCollections && siteCollections.length > 0
                ? <DetailsList
                  columns={
                    location.search.includes('onlyVanitySites=1') 
                      ? vanityColumns
                      : defaultColumns
                  }
                  items={siteCollections}
                  selectionPreservedOnEmptyClick={true}
                  selectionMode={SelectionMode.none}
                  checkboxVisibility={false}
                />
                : <p>No sites where found.</p>
            }
          </div>


          <div className="app-mysites-list-narrow">
            {
              siteCollections && siteCollections.length > 0
                ? siteCollections.map((_, i) => (
                  <div className={`app-mysites-list-narrow-row ${i % 2 == 0 ? 'even' : ''}`}>
                    <div className="app-mysites-list-narrow-field">
                      <h4>Title</h4>
                      <p>{_.title}</p>
                    </div>
                    <div className="app-mysites-list-narrow-field">
                      <h4>Url</h4>
                      <a href={_.url}>{_.url}</a>
                    </div>
                    <div className="app-mysites-list-narrow-field">
                      <h4>Owners</h4>
                      <ul className="app-owner-list">
                        {
                          _.siteOwners && _.siteOwners.map(so => (
                            <li>{so}</li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                ))
                : <p>No sites where found.</p>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="app-page-wrapper">
      <Warnings warnings={warnings} onDismissWarning={dismissWarning} />
      <Error error={error} onDismissError={dismissError} />

      <div className="app-page-content wide">
        <h3 id="app-mysites-header">My Sites</h3>
        {
          onlyVanitySites
            ? (
              <div className="app-mysites-vanity-info">
                <MessageBar>
                  Showing only sites from sharepoint.volvocars.net and collaboration.volvocars.net, click<Link to={''}>here to show all sites</Link>
                </MessageBar>
                <p>If you want to delete a site, use the <a href="https://volvocars.service-now.com/sp?id=sc_cat_item&sys_id=d34403d22b52e100e587593069da15f0" target="_blank">Delete a SharePoint site</a> request in the Self-Service Portal.</p>
              </div>
            )
            : ''
        }

        {
          renderContent()
        }

      </div>

    </div>
  );
}

export default MySites;
