import React from 'react';
import { Icon } from 'office-ui-fabric-react';

import './SiteRenewalSuccess.scss';

const SiteRenewalSuccess = ({ renewal }) => (
    <div className="spgov-site-exp-info-success">
      <Icon iconName="SkypeCircleCheck" />
      <h2>Renewal Successful</h2>
      <p>'{renewal.title}' has been successfully renewed, the site will expire <span className="spgov-bold">{renewal.expirationDate}</span>.</p>
    </div>
);

export default SiteRenewalSuccess;