import React, { useEffect, useState } from 'react';
import { AppContext } from '../../../Commons/AppContext'

import Warnings from '../../Presentationals/Messages/Warnings/Warnings';
import Error from '../../Presentationals/Messages/Error/Error';
import MyReporteesGroups from './MyReporteesGroups';
import { Link } from 'react-router-dom';
import './MyGroups.scss';
import { DetailsList, SelectionMode, Spinner, SpinnerSize, Pivot, PivotItem } from 'office-ui-fabric-react';
import { defaultColumns } from './columns';
import GroupAPI from '../../../API/GroupAPI';
import { Icon } from '@fluentui/react/lib/Icon';
const Spacer = require('react-spacer');


const MyGroups = ({ location }) => {
  const appContext = React.useContext(AppContext);

  const [warnings, setWarnings] = useState([]);
  const [mygroupCount, setMyGroupCount] = useState(0);
  const [reporteeGroupCount, setReporteeGroupCount] = useState(0);
  const [error, setError] = useState(null);
  const dismissWarning = (warningIdentifier) => setWarnings(warnings.filter(_ => _.key !== warningIdentifier));
  const dismissError = () => setError(null);

  const [loadingGroups, setLoadingGroups] = useState(true);
  const [groups, setGroups] = useState([]);
  const [reportgroups, setReportGroups] = useState([]);

  useEffect(() => {
    if (loadingGroups) {
      GroupAPI.getGroups()
        .then(_ => {
          setGroups(_);
          setMyGroupCount(_.filter(item => item.owners.length<=1).length);
        })
        .catch(error => {
          setError(error);
        })
        .finally(_ => {
          setLoadingGroups(false);
        });

      GroupAPI.getDirectReportsGroups(appContext.myDirectReports)
        .then(_ => {
          setReportGroups(_);
          setReporteeGroupCount(_.length);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [loadingGroups]);

  const renderLinks = () => {
    return (
      <div className="mygroups-links">
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <a target='_blank' rel="noopener noreferrer" href='https://volvocars.service-now.com/sp?id=kb_article&sysparm_article=KB0040288'>
                My Groups - Information
                </a>
              </td>
              <td>
                <a target='_blank' rel="noopener noreferrer" href='https://volvocars.service-now.com/sp?id=kb_article&sysparm_article=KB0037502'>
                Sensitivity Labels and Privacy - Information
                </a>
              </td>
              <td>
              <a target='_blank' rel="noopener noreferrer" href='https://volvocars.service-now.com/sp?id=kb_article&sysparm_article=KB0037644'>
                Sensitivity Labels and Privacy - Set or Change
                </a>              
              </td>
            </tr>
            <tr>
            <td>
            <a target='_blank' rel="noopener noreferrer" href='https://volvocars.service-now.com/sp?id=kb_article&sysparm_article=KB0040298'>
                Change or Add Owners
                </a>             
              </td>
              <td>
              <a target='_blank' rel="noopener noreferrer" href='https://volvocars.service-now.com/sp?id=kb_article&sysparm_article=KB0040287'>
                Delete a Group
                </a>
              </td>
              <td>
              <a target='_blank' rel="noopener noreferrer" href='https://volvocars.service-now.com/sp?id=sc_cat_item&sys_id=f7425ec6b5c5b100a7f8391a9c617a09'>
                Restore a Group
                </a>
              </td>
            </tr>
            {/*<tr>*/}
            {/*<td>              */}
            {/*  <Link*/}
            {/*    to={`/${'myreportees'}`}*/}
            {/*    key={'myreportees'}*/}
            {/*    data-linkname={'myreportees'}*/}
            {/*    className={`app-main-navigation-link`}*/}
            {/*  >*/}
            {/*    My Reportees*/}
            {/*  </Link>*/}
            {/*</td>*/}
            {/*<td>*/}
              
            {/*</td>*/}
            {/*<td>*/}
              
            {/*</td>*/}
            {/*</tr>*/}
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    );
  }

  const renderContent = () => {
    if (loadingGroups) {
      return (
        <Spinner size={SpinnerSize.large}
          label="Getting groups.."
        />
      );
    }
    return (
      <div>
        <div>

          <div className="app-mygroups-list-wide">
            {
              groups && groups.length > 0
                ? <DetailsList
                  columns={defaultColumns}
                  items={groups.sort(function (x, y) {
                    let a = x.displayName.toUpperCase(),
                        b = y.displayName.toUpperCase();
                    return a == b ? 0 : a > b ? 1 : -1;
                  })}
                  onRenderRow={(props, defaultRender) => (
                    props.item['owners'].length == 1 ?
                      <div className='single-owner'>
                        {defaultRender({ ...props, styles: { root: { background: '#f9e2ea', borderLeftWidth: 'thick', borderLeftStyle: 'solid',borderLeftColor: '#c0432c' } } })}
                      </div>
                      :
                      <div className='ms-List-cell'>
                        {defaultRender({ ...props })}
                      </div>
                  )}
                  selectionPreservedOnEmptyClick={true}
                  selectionMode={SelectionMode.none}
                  checkboxVisibility={false}
                />
                : <p>Oh, nothing here to see. You don't seem to own any Groups.</p>
            }
          </div>


          <div className="app-mygroups-list-narrow">
            {
              groups && groups.length > 0
                ? groups.map((_, i) => (
                  <div className={`app-mygroups-list-narrow-row ${i % 2 == 0 ? 'even' : ''}`}>
                    <div className="app-mygroups-list-narrow-field">
                      <h4>Title</h4>
                      <p><a href={_.unifiedGroupUrl}>{_.displayName}</a></p>
                    </div>
                    <div>
                      <h4>Description</h4>
                      <p>{_.description}</p>
                    </div>
                    {/* <div>
                      <h4>Created From</h4>
                      <p>{_.createdFrom}</p>
                    </div> */}
                    <div className="app-mygroups-list-narrow-field">
                      <h4>Sensitivity</h4>
                      <p>{_.classification}</p>
                    </div>
                    <div className="app-mygroups-list-narrow-field">
                      <h4>Privacy</h4>
                      <p>{_.visibility}</p>
                    </div>
                    <div className="app-mygroups-list-narrow-field">
                      <h4>Owners</h4>
                      <ul className="app-owner-list">
                        {
                          _.owners && _.owners.map(so => (
                            <li>{so.email}</li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                ))
                : <p> Oh, nothing here to see.You don't seem to own any Groups.</p>
            }
          </div>
        </div>
      </div>
    )
  }

  const msgStyle = {
    display: 'flex',
    width: 'fit-content',
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #c0432c',
    borderLeftWidth: '15px',
    color: '#c0432c',
  };

  const pivotcustomRenderer = (
    link,defaultRenderer)=> {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <span style={{ flex: '0 1 100%' }}>
        {defaultRenderer({ ...link, itemIcon: undefined })}
        {
          reporteeGroupCount > 0 && <span style={{ color: '#c0432c' }}>{` (${reporteeGroupCount})`}</span>
        }
        
      </span>
    );
  }


  return (
    <div className="app-page-wrapper">
      <Warnings warnings={warnings} onDismissWarning={dismissWarning} />
      <Error error={error} onDismissError={dismissError} />

      <div className="app-page-content wide">
        <h3 id="app-mygroups-header">Manage Group(s) Using the Following Links</h3>
        {renderLinks()}
        {
          (mygroupCount > 0 || reporteeGroupCount > 0) &&
          <div style={msgStyle}>
            <Spacer width='10px' />
            <Icon iconName="AlertSolid" title="Groups need more than one owner" />
            <Spacer width='15px' />
            <div>Groups need more than one owner</div>
            <Spacer width='15px' />
          </div>
        }
        <Pivot>
          <PivotItem
            headerText="My Groups"            
          >
            {
              renderContent()
            }
          </PivotItem>
          <PivotItem
            headerText={`Manager action required`} onRenderItemLink={pivotcustomRenderer}
          >
            <MyReporteesGroups groups={reportgroups}></MyReporteesGroups>
          </PivotItem>
        </Pivot>
        {/*<h3 id="app-mygroups-header">My Groups</h3>*/}
        

        {
          //renderContent()
        }

      </div>

    </div>
  );
}

export default MyGroups;
