import React, { useState } from 'react';

import Warnings from '../../Presentationals/Messages/Warnings/Warnings';
import Error from '../../Presentationals/Messages/Error/Error';

import './DefaultPage.scss';


const DefaultPage = () => {
  const [warnings, setWarnings] = useState([])
  const [error, setError] = useState(null);

  const dismissWarning = (warningIdentifier) => setWarnings(warnings.filter(_ => _.key !== warningIdentifier));

  const dismissError = () => setError(null);


  return (
    <div className="app-page-wrapper">
      <Warnings warnings={warnings} onDismissWarning={dismissWarning} />
      <Error error={error} onDismissError={dismissError} />

      <div className="app-page-content">
        <div className="default-page-text">
          <h3>PAGE NOT FOUND</h3>
          <h2>404</h2>
        </div>
      </div>

    </div>
  );
};

export default DefaultPage;
