import React, { Component } from 'react';

import ExpirationAPI from '../../../API/ExpirationAPI';

import { Spinner } from 'office-ui-fabric-react';

import Warnings from '../../Presentationals/Messages/Warnings/Warnings';
import Error from '../../Presentationals/Messages/Error/Error';
import SiteExpirationInformation from '../../Presentationals/SiteExpirationInformation/SiteExpirationInformation';

import './SiteExpiration.scss';
import SiteRenewalSuccess from '../../Presentationals/SiteRenewalSuccess/SiteRenewalSuccess';
import SiteRenewalCancelled from '../../Presentationals/SiteRenewalCancelled/SiteRenewalCancelled';
import SiteDeleted from '../../Presentationals/SiteDeleted/SiteDeleted';

const getSpinnerLabel = (loading, renewing) => {
  if (loading && !renewing) { return 'Loading site information..' }
  else if (!loading && renewing) { return 'Renewing site..' }
  return '';
}

class SiteExpiration extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      warnings: [],
      error: null,
      renewing: false,
      renewal: null,
      loading: true,
      siteExpiration: null,
      cancelled: false,
    };
  }

  componentDidMount() {
    this.getSiteExpiration();
  }

  getSiteExpiration = () => {
    ExpirationAPI.getSiteExpiration(this.props.match.params.siteId)
      .then(siteExpiration => this.setState({ siteExpiration, loading: false }, this.setPreliminaryValidation))
      .catch(error => this.setState({ error, loading: false }));
  }

  postRenewSite = () => {
    this.setState({ renewing: true }, () =>
      ExpirationAPI.postRenewSite(this.props.match.params.siteId)
        .then(renewal => this.setState({ renewal, renewing: false }))
        .catch(error => this.setState({ error, renewing: false }))
    );
  }

  cancelRenewal = () => {
    this.setState({ cancelled: true });
  }

  setPreliminaryValidation = () => {
    const warnings = [];
    const { siteExpiration } = this.state;
    if (siteExpiration) {
      if (!siteExpiration.userIsOwner) {
        warnings.push({
          key: 'insufficient-auth',
          header: 'Insufficient Authority',
          message: 'You are not an Owner or Administrator of the site, and are therefore unable to complete the renewal of the site.'
        });
      }
    }
    this.setState({ warnings });
  }

  dismissWarning = warningIdentifier => {
    const { warnings } = this.state;
    this.setState({ warnings: warnings.filter(_ => _.key !== warningIdentifier) })
  }

  dismissError = () => this.setState({ error: null })

  renderContent = () => {
    const {
      loading,
      renewing,
      renewal,
      siteExpiration,
      cancelled,
    } = this.state;

    if (loading || renewing) {
      return (
        <Spinner
          label={getSpinnerLabel(loading, renewing)}
        />
      );
    } else if (siteExpiration && siteExpiration.isDeleted) {
      return (
        <SiteDeleted siteCollection={siteExpiration} />
      );
    } else if (cancelled) {
      return (
        <SiteRenewalCancelled />
      );
    } else if (renewal) {
      return (
        <SiteRenewalSuccess renewal={renewal} />
      );
    } else {
      return (
        <SiteExpirationInformation
          siteExpiration={siteExpiration}
          onRenewSite={this.postRenewSite}
          onCancelRenewal={this.cancelRenewal}
        />
      );
    }
  }

  render() {
    const {
      error,
      warnings,
    } = this.state;

    return (
      <div className="app-page-wrapper">
        <Warnings warnings={warnings} onDismissWarning={this.dismissWarning} />
        <Error error={error} onDismissError={this.dismissError} />

        <div className="app-page-content">
          {
            this.renderContent()
          }
        </div>

      </div>
    );
  }
}

export default SiteExpiration;
