import { SiteActivity } from "../../../Commons/enums"

const VanityQuestions = [
  {
    text: "How active is the Site?",
    propertyName: "siteActivity",
    options: [
      {
        label: "Active - we use the site on a regular basis",
        value: SiteActivity.Active
      },
      {
        label: "Inactive - the site should be archived",
        value: SiteActivity.RequiresBackup
      },
      {
        label: "No longer in use - can be deleted",
        value: SiteActivity.Obsolete
      }
    ]
  },
  {
    text: "Business criticality: How long can this site be unavailable before causing business disruption?",
    propertyName: "acceptedDowntimeDays",
    renderIf: (answers) => answers["siteActivity"] === SiteActivity.Active,
    options: [
      {
        label: "Less than 1 Day",
        value: 1
      },
      {
        label: "1-2 days",
        value: 2
      },
      {
        label: "3-5 days",
        value: 5
      },
      {
        label: "5+ days",
        value: 7
      }
    ]
  },
  {
    text: "Dependencies: Does the site have any dependencies*?",
    helpText: "* Integrations, Systems writing or reading data to/from the site, Flows etc.",
    propertyName: "hasDependencies",
    renderIf: (answers) => answers["siteActivity"] === SiteActivity.Active,
    options: [
      {
        label: "Yes",
        value: true
      },
      {
        label: "No",
        value: false
      },
    ]
  },
];

export default VanityQuestions;
