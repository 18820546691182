/* eslint-enable no-unused-vars */
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import React, { Component } from 'react';
/* eslint-disable no-unused-vars */
import { Route, Switch, withRouter } from 'react-router-dom';
import AuthAPI from '../../API/AuthAPI';
import UserAPI from '../../API/UserAPI';
import AccessReview from '../../Components/Pages/AccessReview/AccessReview';
import DefaultPage from '../../Components/Pages/DefaultPage/DefaultPage';
import MySites from '../../Components/Pages/MySites/MySites';
import SiteExpiration from '../../Components/Pages/SiteExpiration/SiteExpiration';
import GroupExpiration from '../../Components/Pages/GroupExpiration/GroupExpiration';
import Vanity from '../../Components/Pages/VanityPage/Vanity';
import VanityCancelDeletionPage from '../../Components/Pages/VanityCancelDeletionPage/VanityCancelDeletionPage';
import AppBar from '../../Components/Presentationals/AppBar/AppBar';
import Header from '../../Components/Presentationals/Header/Header';
import './app.scss';
import Error from '../../Components/Presentationals/Messages/Error/Error';
import VanityReschedulePage from '../../Components/Pages/VanityReschedulePage/VanityReschedulePage';
import VanityUATSites from '../../Components/Pages/VanityUATSites/VanityUATSites';
import MyGroups from '../../Components/Pages/MyGroups/MyGroups';
import MyReporteesGroups from '../../Components/Pages/MyGroups/MyReporteesGroups';
import { AppContext } from '../../Commons/AppContext'
const routes = [
  {
    path: '/expiration/sites/:siteId',
    component: SiteExpiration
  },
  {
    path: '/expiration/groups/:groupId',
    component: GroupExpiration
  },
  {
    path: '/review/:reviewId',
    component: AccessReview,
    featureFlagName: 'AccessReview'
  },
  {
    path: '/sites/:siteId/vanitysurvey',
    component: Vanity,
  },
  {
    path: '/sites/:siteId/vanitydeletion',
    component: VanityCancelDeletionPage,
  },
  {
    path: '/sites/vanity-uat',
    component: VanityUATSites,
  },
  {
    path: '/sites/:siteId/reschedule',
    component: VanityReschedulePage,
  },
  {
    path: '/sites',
    component: MySites,
  },
  {
    path: '/',
    component: MyGroups
  },
  {
    path: '/myreportees',
    component: MyReporteesGroups
  },
  {
    path: '*',
    component: DefaultPage
  },
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      accountName: null,
      error: null,
      featureFlags: null
    }
  }

  async componentWillMount() {
    const account = await AuthAPI.getCurrent().login()
    if (account) {
      this.setState({ accountName: account.name }, () => {
        UserAPI.getUser()
          .then(_ => this.setState({ user: _ },
            () => {
              if (this.state.user) {
                const ffDict = {};
                this.state.user.featureFlags.forEach(_ => ffDict[_.name] = _.enabled);
                this.setState({ featureFlags: ffDict });
              };
            }))
          .catch(_ => this.setState({ error: _ }));
      });
    }
  }

  dismissError = () => this.setState({ error: null })

  render() {
    if (window.location.href.indexOf('id_token') >= 0) { return; }

    const { accountName, user, error, featureFlags } = this.state;

    if (!accountName) {
      return (
        <div className="loading-wrapper">
          <Spinner size={SpinnerSize.large} label="Signing in.." />
        </div>
      )
    }

    return (
      <div id="app">
        <Header transparent history={this.props.history} />
        <AppBar user={user} />
        <Error error={error} onDismissError={this.dismissError} />
        <AppContext.Provider value={{
          myDirectReports: user ? user?.myDirectReports: []
        }}>
          {
          featureFlags
            ? (
              <Switch>
                {
                  routes.map((route, index) => {
                    const key = `route-${index}`;
                    const featureFlagName = route.featureFlagName;
                    // Dont render route if featureflag is defined but set to false/doesn't exist
                    if (featureFlagName && !featureFlags[featureFlagName]) { }
                    else {
                      return (<Route key={key} strict exact {...route} />);
                    }
                  })
                }
              </Switch>
            )
            : (
              <div className="loading-wrapper">
                <Spinner size={SpinnerSize.large} label="Loading.." />
              </div>
            )
        }
        </AppContext.Provider>
      </div>
    );
  }
}

export default withRouter(App);
